@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.footer {
  background-color: variables.$blue;
  color: variables.$white;

  .footer__wrap {
    padding: 30px 0 0 0;

    @include media.m1281 {
      display: grid;
      grid-template-columns: 242px 79px 550px 79px 218px;
    }

    @include media.m1681 {
      display: grid;
      grid-template-columns: 235px 257px 550px 285px 218px;
    }
  }

  .footer__logo {
    img {
      width: 166px;
      height: 40px;

      @include media.m768 {
        width: 235px;
        height: 57px;
      }
    }
  }

  .footer__list {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include media.m1281 {
      grid-column: 3/4;
      grid-row: 1/2;
      flex-direction: row;
      margin-top: 0;
      gap: 40px;
      margin-top: 20px;
    }
  }

  .footer__li {
    font-size: 14px;
  }

  .footer__info {
    display: flex;
    align-content: center;
    margin-top: 30px;
    justify-content: space-between;

    @include media.m401 {
      flex-direction: column;
    }

    @include media.m1281 {
      grid-column: 5/6;
      grid-row: 1/2;
      margin-top: 0;
      justify-content: initial;
      gap: 18px;
      align-items: flex-end;
    }
  }

  .footer__phone {
    a {
      font-size: 16px;
      line-height: 22px;
      @include mixins.r-bold;
    }
  }

  .footer__social {
    @include media.m1281 {
      order: -1;
    }
  }

  .footer-social {
    display: flex;
    gap: 13px;

    @include media.m401 {
      margin-top: 20px;
    }

    @include media.m1281 {
      margin-top: 0;
    }

    .footer-social__li {
      width: 34px;

      a {
        height: 20px;
        width: 100%;
        display: block;
      }
    }

    @for $i from 0 through 2 {
      .footer-social-#{$i} {
        a {
          background: url('/wwwroot/img/footer/#{$i}.svg') no-repeat;
          background-size: contain;
        }
      }
    }
  }

  .footer__buttons {
    margin-top: 32px;
    display: flex;
    gap: 8px;

    @include media.m1281 {
      grid-column: 3/4;
      grid-row: 2/3;
      margin-top: 10px;
      justify-content: space-between;
    }
  }

  .footer__link {
    @include mixins.center;
    width: 167px;
    height: 50px;
    background-color: variables.$black;
    border-radius: 56px;

    @include media.m1281 {
      width: 247px;
      height: 60px;
    }

    &:nth-child(1) {
      background-image: url('/wwwroot/img/acccount/button-1.png');
      background-color: variables.$black;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: 0.3s all;

      &:hover {
        background-image: url('/wwwroot/img/acccount/button-1-hover.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: variables.$white;
      }
    }
    &:nth-child(2) {
      background-image: url('/wwwroot/img/acccount/button-2.png');
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: center;
      transition: 0.3s all;

      &:hover {
        background-image: url('/wwwroot/img/acccount/button-2-hover.png');
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: center;
        background-color: variables.$white;
      }
    }
  }

  .footer__policy {
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include media.m1281 {
      grid-column: 5/6;
      grid-row: 2/3;
      flex-direction: column;
      margin-top: 23px;
      align-items: flex-end;
    }

    a {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .footer__copyright {
    margin-top: 10px;
    font-size: 14px;
    padding-bottom: 95px;

    @include media.m1281 {
      grid-column: 1/2;
      grid-row: 2/3;
      margin-top: 55px;
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.team {
  @include media.m1281 {
    background: url('/wwwroot/img/team/bg.webp') no-repeat;
    background-size: cover;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 100px;
  }

  .team__h2 {
    @include media.m768 {
      text-align: center;
    }
    @include media.m1281 {
      padding-top: 83px;
    }
  }

  .team__blocks {
    margin-top: 40px;

    @include media.m1281 {
      padding-bottom: 100px;
    }
  }

  .swiper-wrapper {
    @include media.m1281 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
    }
  }

  .team-block {
    border-radius: 10px;
    background-color: variables.$white;
    height: auto;
  }

  .team-block__wrap {
    padding: 20px 20px 50px 20px;

    @include media.m1281 {
      padding: 20px 20px 40px 20px;
    }
  }

  .team-block__top {
    @include media.m1681 {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    img {
      width: 104px;
      height: 104px;
    }
  }

  .team-block__h3 {
    font-size: 18px;
    @include mixins.r-bold;
    color: variables.$blue;
    margin-top: 20px;

    @include media.m1681 {
      margin-top: 0;
    }
  }

  .team-block__p {
    margin-top: 16px;
    font-size: 16px;
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.program {
  .program__h2 {
    @include media.m768 {
      text-align: center;
    }
  }
  .program__blocks {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto;
    gap: 10px;

    @include media.m1681 {
      gap: 30px;
    }
  }
  .program-block {
    border-radius: 10px;
    background: url('/wwwroot/img/program/bg-mob-1.webp') no-repeat;
    background-size: cover;
    background-position: 100% 29%;
    position: relative;

    @include media.m1024 {
      background: url('/wwwroot/img/program/bg.webp') no-repeat;
      background-size: cover;
      background-position: initial;
      display: flex;
      justify-content: flex-end;
      border-radius: 50px;
    }

    &:nth-child(1) {
      img {
        @include media.m1681 {
          top: -40px;
        }
      }
    }

    img {
      display: none;

      @include media.m1440 {
        display: block;
        position: absolute;
        bottom: 0;
        left: -20px;
        height: 70%;
        width: fit-content;
      }

      @include media.m1681 {
        left: 0;
        height: 112%;
      }
    }
  }
  .program-block__wrap {
    padding: 50px 20px 35px 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    @include media.m1024 {
      flex-direction: row;
      padding: 20px;
    }

    @include media.m1281 {
      gap: 50px;
    }

    @include media.m1681 {
      gap: 214px;
    }
  }
  .program-block__left {
    flex-shrink: 0;
  }

  .program-block__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .program-block__h3 {
    font-size: 30px;
    @include mixins.t-regular;
    color: variables.$blue;
    max-width: 166px;

    @include media.m1024 {
      margin-top: 90px;
    }
  }
  .program-block__span {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    display: block;
    background: url('/wwwroot/img/features/down.svg') no-repeat;
    background-size: contain;
    background-position-x: 50%;
    cursor: pointer;

    @include media.m1024 {
      display: none;
    }
  }
  .program-block__right {
    border-radius: 40px;
    background: variables.$white;
    max-height: 0;
    opacity: 0;

    @include media.m1024 {
      width: 780px;
      max-height: 100%;
      opacity: 1;
    }
  }
  .program-block__wrapper {
    padding: 30px;
  }
  .program-block__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .program-block__li {
    font-size: 16px;
    @include media.m1681 {
      font-size: 18px;
    }
  }

  .transform {
    .program-block__right {
      max-height: 100%;
      opacity: 1;
      transition: 0.3s all;
    }

    .program-block__span {
      transition: 0.3s all;
      transform: rotate(180deg);
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.uniform {
  background: variables.$white;
  box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.1);
  border-radius: 50px;
  margin-left: 16px;
  margin-right: 16px;

  @include media.m1024 {
    margin-left: 30px;
    margin-right: 30px;
  }

  @include media.m1281 {
    background: url('/img/uniform/line.png') no-repeat;
    background-size: cover;
  }

  .container {
    padding: 0;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    row-gap: 30px;

    @include media.m1024 {
      display: grid;
      grid-template-columns: 50% 50%;
    }

    @include media.m1281 {
      grid-template-columns: 54% 54%;
    }
  }

  &__text {
    padding: 38px 20px 0;

    @include media.m1024 {
      padding: 40px 0;
    }

    @include media.m1281 {
      padding: 78px 0;
    }

    h2 {
      margin-bottom: 38px;
    }

    p {
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        @include mixins.r-semy;
      }

      br {
        display: none;

        @include media.m1281 {
          display: block;
        }
      }
    }
  }

  &__img {
    line-height: 0;

    @include media.m1024 {
      display: flex;
      align-items: flex-end;
      margin-left: -20px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.class {
  .btn {
    margin-top: 38px;

    @include media.m768 {
      margin: 38px auto 0;
      padding: 20px 180px;
    }

    @include media.m1281 {
      margin-top: 78px;
    }
  }

  .class__h2 {
    @include media.m768 {
      text-align: center;
    }
  }
  .class__blocks {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;

    @include media.m768 {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 10px;
    }

    @include media.m1281 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .class-block {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
    border-radius: 10px;
  }
  .class-block__wrap {
    padding: 20px 20px 20px 13px;
    display: flex;
    align-items: center;
    gap: 12px;

    @include media.m1281 {
      padding: 20px;
    }

    @include media.m1681 {
      gap: 20px;
    }
  }
  .class-block__left {
    img {
      width: 50px;
      height: 50px;

      @include media.m361 {
        width: 100px;
        height: 100px;
      }
    }
  }

  .class-block__h3 {
    font-size: 20px;
    @include mixins.t-regular;
    color: variables.$blue;

    @include media.m1681 {
      font-size: 30px;
    }
  }
  .class-block__p {
    margin-top: 5px;
    font-size: 16px;

    @include media.m1681 {
      font-size: 18px;
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.account {
  border-radius: 50px;
  background: url('/wwwroot/img/acccount/bg-mob.png') no-repeat;
  background-size: cover;
  margin-left: 20px;
  margin-right: 20px;

  @include media.m1024 {
    margin-left: 30px;
    margin-right: 30px;
  }

  @include media.m1281 {
    background: url('/wwwroot/img/acccount/bg.webp') no-repeat;
    background-size: cover;
  }

  .account__wrap {
    padding: 20px 0 34px 0;

    @include media.m401 {
      padding: 34px 20px 34px 20px;
    }

    @include media.m768 {
      padding: 40px 20px 40px 20px;
    }

    @include media.m1281 {
      padding: 40px 0;
    }

    @include media.m1681 {
      padding: 82px 0;
    }
  }
  .account__content {
    @include media.m1281 {
      display: flex;
      gap: 40px;
      margin-top: 30px;
    }
  }
  .account__left {
    position: relative;
    margin-top: 25px;

    @include media.m1281 {
      margin-top: 0;
      width: 503px;
    }

    @include media.m1681 {
      margin-top: 0;
      width: 1005px;
    }

    img {
      width: 100%;
      height: auto;

      @include media.m1024 {
        width: inherit;
      }

      @include media.m1681 {
        width: 100%;
      }
    }
  }

  &__left-sticker {
    margin: -25px auto 0;
    width: 215px;
    box-shadow: 0px 3.13px 15.65px 0px rgba(18, 18, 18, 0.1);
    border-radius: 18px;
    background-color: variables.$white;
    padding-right: 18px;
    display: flex;
    align-items: center;
    gap: 18px;
    position: relative;
    font-size: 11px;
    line-height: 13px;
    overflow: hidden;

    @include media.m1281 {
      position: absolute;
      margin: 0;
      right: 50%;
      transform: translateX(50%);
      bottom: -25px;
    }

    @include media.m1681 {
      right: 30px;
      bottom: -30px;
      transform: unset;
      width: 250px;
      font-size: 13px;
      line-height: 15px;
      gap: 23px;
      border-radius: 22px;
    }

    img {
      width: 59px !important;
      height: 59px;
      flex-shrink: 0;
      transform: scale(1.02);

      @include media.m1681 {
        width: 76px !important;
        height: 76px;
      }
    }
  }

  .account__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;

    @include media.m1281 {
      margin-top: 0;
    }
  }
  .account__li {
    font-size: 16px;
    line-height: 1.65;
    padding-left: 26px;
    position: relative;

    @include media.m1681 {
      font-size: 25px;
    }

    &::before {
      content: '';
      width: 3px;
      height: 3px;
      background-color: variables.$black;
      border-radius: 50%;
      position: absolute;
      top: 17px;
      left: 10px;
    }
  }
  .account__bottom {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    gap: 11px;

    @include media.m768 {
      flex-direction: row;
      gap: 54px;
    }
  }
  .account__link {
    @include mixins.center;
    width: 248px;
    height: 45px;
    background-color: variables.$black;
    border-radius: 56px;

    @include media.m361 {
      width: 306px;
    }

    @include media.m401 {
      width: 247px;
      height: 56px;
    }

    &:nth-child(1) {
      background-image: url('/wwwroot/img/acccount/button-1.png');
      background-color: variables.$black;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: 0.3s all;

      &:hover {
        background-image: url('/wwwroot/img/acccount/button-1-hover.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-color: variables.$white;
      }
    }
    &:nth-child(2) {
      background-image: url('/wwwroot/img/acccount/button-2.png');
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s all;
      background-size: 130px;

      @include media.m768 {
        background-size: initial;
      }

      &:hover {
        background-image: url('/wwwroot/img/acccount/button-2-hover.png');
        background-repeat: no-repeat;
        background-position: center;
        background-color: variables.$white;
      }
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.features {
  .features__h2 {
    word-wrap: break-word;

    @include media.m768 {
      text-align: center;
    }
  }
  .features__blocks {
    display: grid;
    grid-template-columns: auto;
    row-gap: 30px;
    margin-top: 40px;

    @include media.m768 {
      grid-template-columns: repeat(2, 344px);
      grid-template-rows: repeat(2, auto);
      column-gap: 30px;
      margin-top: 70px;
    }

    @include media.m1024 {
      grid-template-columns: repeat(2, 449px);
      row-gap: 40px;
    }

    @include media.m1281 {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(2, auto);
      margin-top: 150px;
      column-gap: 14px;
    }
  }
  .features__block {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
    border-radius: 10px;
  }
  .features-block {
    &:nth-child(4) {
      img {
        margin-left: 50%;

        @include media.m1681 {
          transform: translate(-50%, -63px);
        }
      }
    }
  }
  .features-block__top {
    img {
      transform: translate(-50%, -25px);
      margin-left: 50%;
      width: 100%;
      max-width: 100%;
      height: auto;

      @include media.m361 {
        width: inherit;
      }

      @include media.m768 {
        width: 100%;
      }

      @include media.m1024 {
        width: inherit;
      }

      @include media.m1281 {
        transform: translate(-50%, -63px);
      }
    }
  }

  .features-block__wrap {
    padding: 0 16px 30px 16px;

    @include media.m1281 {
      display: flex;
      flex-direction: column;
    }
  }
  .features-block__h3 {
    font-size: 25px;
    @include mixins.t-regular;
    margin-top: -16px;
    color: variables.$blue;

    @include media.m1681 {
      margin-top: -52px;
    }
  }
  .features-block__span {
    width: 100%;
    height: 30px;
    margin-top: 20px;
    margin: 20px auto 0 auto;
    display: block;
    cursor: pointer;
    position: relative;

    @include media.m1281 {
      display: none;
    }

    &::before {
      content: '';
      width: 30px;
      height: 30px;
      background: variables.$blue;
      position: absolute;
      background: url('/wwwroot/img/features/down.svg') no-repeat;
      background-size: contain;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
    }
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background: variables.$blue;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
    }
  }
  .features-block__p {
    margin-top: 6px;
    margin-top: 20px;
    max-height: 0;
    opacity: 0;
    font-size: 16px;

    @include media.m1281 {
      max-height: 100%;
      opacity: 1;
    }
  }

  .transform {
    .features-block__p {
      max-height: 100%;
      opacity: 1;
      transition: 0.3s all;
    }

    .features-block__span {
      &::before {
        transition: 0.3s all;
        transform: rotate(180deg) translateX(50%);
      }
    }
  }
}

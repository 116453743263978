@use "media" as *;
@use "variables" as *;

@mixin r-regular {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin r-medium {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

@mixin r-semy {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

@mixin r-bold {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

@mixin r-extra {
  font-family: 'Raleway', sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
}

@mixin t-regular {
  font-family: 'Tenor Sans', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin m-regular {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@mixin h2 {
  @include t-regular;
  font-size: 28px;
  line-height: 1.2;
  color: $blue;

  @include m361 {
    font-size: 35px;
  }

  @include m768 {
    font-size: 45px;
  }

  @include m1281 {
    font-size: 60px;
  }

  @include m1681 {
    font-size: 70px;
  }
}

.btn {
  @include center;
  padding: 16px;
  @include r-medium;
  cursor: pointer;
  transition: 0.3s;
  font-size: 20px;

  &:hover {
    background-color: #f0a5a9;
  }
}

.btn-blue {
  font-size: 20px;
  line-height: 26px;
  padding: 20px;
  width: 100%;
  background-color: $blue;
  border-radius: 56px;
  color: $white;
  transition: 0.3s all;

  &:hover {
    color: $white;
    background: #f0a5a9;
    box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
  }

  &:focus {
    color: $white;
  }

  @include m768 {
    padding: 20px 40px;
    width: fit-content;
  }
}

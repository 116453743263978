@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.header {
  height: 75px;

  @include media.m1440 {
    height: 100px;
  }
  .header__container {
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .header__logo {
    width: 93px;
    height: 33px;

    @include media.m768 {
      width: 166px;
      height: 40px;
    }

    @include media.m1681 {
      width: 235px;
      height: 57px;
      margin-right: 76px;
    }
  }

  .header__menu {
    display: none;

    @include media.m1440 {
      display: flex;
      align-items: center;
      gap: 27px;
      font-size: 16px;
      @include mixins.r-medium;
      margin-right: 32px;
    }
  }
  .header__menu-li {
    transition: 0.3s all;

    &:hover {
      color: #f0a5a9;
    }
  }
  .header__social {
    display: none;

    @include media.m1440 {
      display: flex;
      align-items: center;
      gap: 32px;
    }
  }
  .header__phone-block {
    display: flex;
    gap: 10px;
  }
  .header__social-li {
    &:first-child {
      margin-right: 4px;
    }
    img {
      max-width: 28px;
      max-height: 19px;
    }
  }

  .header__phone {
    display: none;

    @include media.m1440 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 10px;
    }
  }
  .header__phone-li {
    @include mixins.r-bold;
    font-size: 14px;
    line-height: 18px;

    &:nth-child(1) {
      font-size: 20px;
      line-height: 26px;
      @include mixins.r-bold;
    }
  }
  .header__checkbox {
    display: none;

    @include media.m1681 {
      display: block;
    }
  }

  .header__hamburger {
    width: 39px;
    height: 39px;
    cursor: pointer;
    background-color: variables.$white;
    border-radius: 10px;
    flex-shrink: 0;
    padding: 0 7px;

    @include media.m1440 {
      display: none;
    }
  }
  .header__inner {
    width: 25px;
    height: 2px;
    background-color: #f0a5a9;
    position: relative;
    top: 50%;

    &:before {
      position: absolute;
      width: 100%;
      height: 2px;
      transition-duration: 0.15s;
      background-color: #f0a5a9;
      display: block;
      content: '';
      top: -8px;
      transition: top 75ms ease 0.12s, opacity 75ms ease;
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 2px;
      transition-duration: 0.15s;
      background-color: #f0a5a9;
      display: block;
      content: '';
      top: 8px;
      transition: top 75ms ease 0.12s, opacity 75ms ease;
    }
  }

  .active {
    .header__inner {
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(45deg);

      &::before {
        top: 0;
        transition: top 75ms ease, opacity 75ms ease 0.12s;
        opacity: 0;
      }

      &::after {
        bottom: 0;
        transition: bottom 75ms ease,
          transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
        transform: rotate(-90deg);
        top: 0px;
        left: 0px;
      }
    }
  }

  .popup-mob {
    position: absolute;
    transform: translate3d(-150%, 0, 0);
    top: 75px;
    left: 0;
    width: 100%;
    transition-duration: 0.5s;
    background-color: #fdd7e4;
    color: variables.$white;
    height: 100vh;
    z-index: 10;

    @include media.m1024 {
      width: 400px;
      top: 0;
    }

    .popup-mob__container,
    .popup-mob__wrap {
      padding: 0 16px;
    }

    .popup-mob__options {
      background-color: variables.$white;
      border-radius: 10px;
      margin-top: 46px;
      width: 341px;
      margin-left: 0;
    }
    .popup-mob-options {
      .header-options-selected {
        a {
          font-size: 16px;
          @include mixins.r-medium;
          color: variables.$black;
        }
      }
      .header-options__wrapper {
        justify-content: space-between;
        padding: 6px 20px;
      }
    }

    .transform {
      span {
        transform: rotate(181deg);
        transition: 0.3s;
      }

      .header-options-language {
        display: block;
        width: 341px;
        top: 81px;
        color: variables.$black;
      }
    }

    .popup-mob__link {
      background-color: #f0a5a9;
      font-size: 16px;
      padding: 16px 107px;
      color: variables.$white;
      border-radius: 52px;
      width: fit-content;
      @include mixins.r-medium;
      margin-top: 25px;

      &:hover {
        transition: 0.3s all;
        background-color: variables.$white;
        color: variables.$blue;
      }
    }

    .popup-mob__list {
      margin-top: 41px;
    }
    .popup-mob__li {
      text-align: left;
      font-size: 16px;
      color: variables.$black;
      @include mixins.r-medium;
      transition: 0.3s all;
      display: block;

      &:hover {
        color: variables.$white;
      }

      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }

    .popup-mob__bottom {
      margin-top: 41px;
      border-top: 1px solid variables.$white;
      max-width: 341px;
    }

    .popup-mob__content {
      padding-top: 41px;
      display: flex;
      justify-content: space-between;
      text-align: left;
    }
    .popup-mob__phone {
      color: variables.$black;
      a {
        font-size: 20px;
        line-height: 26px;
        @include mixins.r-bold;
      }
      p {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .active-popup {
    transform: translate3d(0, 0, 0);
    transition-duration: 0.5s;
  }

  .header__options {
    @include media.m768 {
      position: relative;
      margin: 0px 30px 0 0;
      background-color: variables.$white;
    }

    @include media.m1440 {
      display: block;
      margin-top: 10px;
    }
  }

  .header-options {
    border-radius: 10px;
    font-size: 16px;
    @include mixins.r-medium;
    height: 100%;
    margin-left: auto;
    margin-right: 10px;

    @include media.m1440 {
      margin-left: 0;
    }

    .header-options__wrapper {
      display: flex;
      align-items: center;
    }

    .header-options-selected {
      a {
        margin-right: 20px;
      }
    }

    .header-options-language {
      display: none;
      margin-top: 10px;
      transition: 0.3s;
      position: absolute;
      top: 22px;
      background-color: #ffffff;
      width: 100%;
      border-radius: 0 0 10px 10px;
    }

    .select2-selection__rendered {
      font-size: 12px !important;
      @include mixins.r-medium;
      padding: 5px 30px 5px 5px;
      min-width: 140px;
      text-align: left;

      @include media.m361 {
        font-size: 14px !important;
        padding: 5px 30px 5px 10px;
      }

      @include media.m768 {
        font-size: 16px !important;
        padding: 5px 40px 5px 14px;
      }
    }
    .select2-selection {
      height: auto !important;
      padding: 0;
    }

    .select2-results__option {
      cursor: pointer;
    }
    .select2-results {
      display: block;
    }

    .select2-selection__arrow {
      top: -5px;
      right: -5px !important;

      @include media.m768 {
        top: -7px;
        right: 1px !important;
      }
    }

    .select2-dropdown {
      left: 0;
      width: 165px !important;
      border: none;
    }
  }
  .transform {
    span {
      transform: rotate(181deg);
      transition: 0.3s;
    }

    .header-options-language {
      display: block;
    }
  }
}

@use "../settings/media";

.video {
  @include media.m1681 {
    background: url('/wwwroot/img/video/bg.webp') no-repeat;
    background-size: cover;
    margin-top: 130px;
    padding-bottom: 100px;
  }

  iframe {
    display: none;
  }

  .video__h2 {
    text-align: center;

    @include media.m1681 {
      max-width: 1491px;
      padding-top: 73px;
    }
  }
  .video__blocks {
    padding: 0 20px;
    margin: 47px 20px 0 -11px;

    @include media.m361 {
      margin: 47px 20px 0 -27px;
    }

    @include media.m401 {
      margin: 47px 0 0 0;
    }

    @include media.m1681 {
      margin-top: 80px;
      padding: 0 30px;
    }
  }

  .video-block {
    height: 212px;
    overflow: hidden;
    border-radius: 30px;
    position: relative;

    @include media.m768 {
      height: 250px;
    }

    @include media.m1281 {
      background: #ffffff;
      height: 387px;
      position: relative;
    }

    .video-block__player-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video-block__player-overlay {
      width: 100%;
      height: 100%;
      background: white;
      opacity: 0.2;
      position: absolute;
    }
    .video-block__player-play {
      width: 108px;
      height: 108px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      background-image: url('/wwwroot/img/video/play-rose.svg');
      background-size: 63px 63px;
      background-position: 65% 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s all;
      cursor: pointer;

      &:hover {
        background-image: url('/wwwroot/img/video/play.svg');
      }
    }
  }

  .video__swiper-wrapper {
    align-items: center;
  }

  .video__bottom {
    margin-top: 31px;
    display: flex;
    align-items: center;
    gap: 40px;

    @include media.m768 {
      display: none;
    }
  }

  .video__navigation {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 190px;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    @include media.m1281 {
      width: fit-content;
      margin: 0 0 0 32%;
    }

    .video__prev,
    .video__next {
      display: block;
      cursor: pointer;
      width: 48px;
      height: 48px;
    }
    .video__prev {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;
    }
    .video__next {
      background: url('/wwwroot/img/join/next.png') no-repeat;
      background-size: contain;
    }
  }

  .video__pagination {
    background: #fdd9e5;
    position: absolute;
    top: 50%;
    width: 68px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);

    @include media.m1281 {
      display: block;
      width: 505px;
      position: relative;
    }

    span {
      background: #cf656b;
    }
  }

  .btn {
    margin-top: 38px;

    @include media.m768 {
      margin: 38px auto 0;
      padding: 20px 139px;
    }

    @include media.m1281 {
      margin-top: 58px;
    }
  }
}

@use "../settings/media" as *;
@use "../settings/mixins" as *;
@use "../settings/variables" as *;

.about {
  @include m1681 {
    margin-top: 160px;
  }

  .about__blocks {
    display: grid;
    grid-template-columns: auto;
    gap: 30px;

    @include m1024 {
      grid-template-columns: repeat(3, 288px);
    }

    @include m1281 {
      grid-template-columns: repeat(3, 1fr);
    }

    @include m1681 {
      gap: 75px;
    }
  }

  .about-block {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.1);
    border-radius: 50px;

    @include m768 {
      border-radius: 10px;
    }
  }
  .about-block__top {
    margin-top: 10px;
    @include m1024 {
      margin-top: -38px;
    }
    @include m1281 {
      margin-top: -44px;
    }
    @include m1681 {
      margin-top: -51px;
    }
    img {
      width: 100%;
      transform: translate(-50%, 0);
      margin-left: 50%;
      height: auto;

      @include m361 {
        width: 344px;
      }

      @include m1024 {
        width: 288px;
      }
      @include m1281 {
        width: 100%;
      }
    }
  }

  .about-block__wrap {
    padding: 0 20px 30px 20px;
  }
  .about-block__bottom {
    @include m1024 {
      margin-top: 10px;
    }
  }
  .about-block__h3 {
    font-size: 25px;
    @include t-regular;
    color: $blue;

    @include m1681 {
    }
  }
  .about-block__p {
    margin-top: 16px;
    font-size: 16px;
  }
}

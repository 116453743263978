@use "media";
@use "mixins";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  background-color: #fdfbfb;
  @include mixins.r-regular;
  overflow-x: hidden;
  line-height: 1.2;
}

.container {
  position: relative;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;

  @include media.m768 {
    padding: 0;
    width: 720px;
  }

  @include media.m1024 {
    padding: 0;
    width: 928px;
  }

  @include media.m1281 {
    width: 1170px;
  }

  @include media.m1681 {
    width: 1546px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 73px;
}

.wrap {
  padding: 0;
}

.main {
  flex: 1;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

ul,
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
}

button {
  cursor: pointer;
  border: none;
  background-color: initial;
}

section {
  margin-top: 68px;

  @include media.m768 {
    margin-top: 130px;
  }
}

h2 {
  @include mixins.h2;
}

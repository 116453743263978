@use '../settings/media' as *;
@use '../settings/mixins' as *;
@use '../settings/variables' as *;

.form {
  background: url('/wwwroot/img/form/bg-mob.webp') no-repeat;
  background-size: cover;
  border-radius: 50px;

  @include m1281 {
    background: url('/wwwroot/img/form/bg.webp') no-repeat;
    background-size: cover;
    margin: 0 30px;
  }

  @include m768 {
    margin-top: 160px;
  }

  @include m1681 {
    padding-bottom: 25px;
  }

  .form__h2 {
    font-size: 35px;
    padding-top: 20px;
    text-align: center;

    @include m1281 {
      font-size: 60px;
      padding-top: 62px;
    }

    @include m1681 {
      font-size: 67px;
    }
  }

  .form__descript {
    margin-top: 30px;
    font-size: 20px;
    line-height: 1.33;
    text-align: center;
    @include t-regular;

    @include m1281 {
      font-size: 30px;
      margin-top: 20px;
    }
  }

  .form__wrap {
    display: flex;
    flex-direction: column;

    @include m1281 {
      display: grid;
      grid-template-columns: 610px 67px auto;
      grid-template-rows: auto;
      margin-top: 75px;
    }

    @include m1681 {
      grid-template-columns: 610px 174px auto;
    }
  }
  .form__info {
    margin-top: 37px;
    max-width: 100%;
    overflow: hidden;

    @include m1281 {
      grid-column: 3/4;
      grid-row: 1 / 2;
      margin-top: 0;
      height: fit-content;
    }
  }

  .form__img {
    height: 225px !important;
    overflow: hidden;
    border-radius: 40px;
    max-width: 100%;

    @include m1281 {
      height: 470px !important;
    }

    @include m1681 {
      height: 590px !important;
    }

    ymaps {
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .form__bottom {
    @include m1281 {
      grid-column: 1/2;
      grid-row: 2/3;
      display: flex;
      flex-direction: column;
    }

    @include m1681 {
      flex-direction: row;
      gap: 33px;
    }
  }

  .form__panel {
    margin-top: 44px;

    @include m1281 {
      margin-top: 0;
    }

    @include m1681 {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  .info {
    margin-top: 14px;

    @include m768 {
      margin-top: 25px;
    }

    @include m1681 {
      margin-top: 17px;
    }

    p {
      font-size: 16px;
      margin-top: -3px;
    }

    a {
      @include r-semy;
    }
  }
  .info-first {
    margin-top: 24px;

    @include m1681 {
      margin-top: 17px;
    }
  }

  .button {
    @include center;
    transition: 0.3s;
    background-color: $blue;
    color: $white;
    margin: 40px auto;
    padding: 20px;
    width: 100%;
    font-size: 20px;
    border-radius: 56px;

    @include m768 {
      width: fit-content;
      padding: 20px 81px;
    }

    @include m1281 {
      margin: 40px 0;
    }

    @include m1681 {
      margin: 39px 0 92px 0;
    }

    &:hover {
      color: $white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }
  }

  .form-check {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 12px;
    height: 12px;
    border: 1px solid $blue;
    border-radius: 2px;
    flex-shrink: 0;
  }

  .form-check + label::before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $blue;
    cursor: pointer;
    border-radius: 2px;
  }

  .form-check:checked + label::before {
    background: url('/wwwroot/img/form/check.svg') no-repeat;
    background-position: 50% 50%;
    background-size: 6px 6px;
  }

  .form-check-label {
    font-size: 14px;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    text-align: left;

    @include m768 {
      font-size: 20px;
      line-height: normal;
    }

    a {
      text-decoration: underline;
    }
  }

  .school-request-age,
  .school-request-lesson {
    margin-bottom: 9px;

    @include m1681 {
      margin-bottom: 21px;
    }
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;

    @include m1681 {
      margin-bottom: 21px;
    }

    input,
    textarea {
      width: 100%;
      border-radius: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      outline: none;
      font-size: 16px;
      line-height: 24px;
      border: none;
      @include r-regular;

      @include m768 {
        font-size: 18px;
      }
    }
    input {
      height: 50px;
    }

    textarea {
      height: 86px;
      padding: 10px;
    }

    input::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: $black;
      @include r-regular;
      opacity: 1;

      @include m768 {
        font-size: 18px;
      }
    }

    input::focus {
      outline: none;
    }
  }

  .field {
    position: relative;

    .error-text {
      display: none;
      position: absolute;
      top: 100%;
      color: #bf4b91;
      font-size: 14px;
    }

    .email-error-text {
      display: none;
      position: absolute;
      top: 100%;
      color: #bf4b91;
      font-size: 14px;
    }
    .phone-error-text {
      display: none;
      position: absolute;
      top: 100%;
      color: #bf4b91;
      font-size: 14px;
    }
  }

  .field.error {
    input {
      border: 1px solid #bf4b91;
    }

    textarea {
      border: 1px solid #bf4b91;
    }

    .error-text {
      display: block;
    }
  }

  .field.input-error {
    .email-error-text,
    .phone-error-text {
      display: block;
    }
  }
}

.chore-form {
  margin-top: 40px;

  @include m1281 {
    margin-top: 130px;
  }

  .field {
    margin-bottom: 24px;
  }

  .error-text,
  .phone-error-text {
    display: none;
  }

  input,
  textarea {
    height: 53px;
    border: 1.5px solid #fdd9e5;
    width: 100%;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 18px;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    background-color: none !important;
  }

  input,
  textarea::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: $black;
    @include r-regular;

    @include m768 {
      font-size: 18px;
    }
  }

  .radiobuttons,
  .chore-form__contacts {
    margin-top: 24px;
  }

  .form-control {
    label {
      margin-top: 24px;
    }
  }

  .form-date {
    padding-right: 20px;
  }

  input,
  textarea:focus {
    outline: none;
  }

  textarea {
    height: 168px;
    padding-top: 10px;
  }

  textarea::placeholder {
    color: #bcb7b7;
  }

  .chore-form__contact {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
    border-radius: 10px;
    margin-bottom: 45px;
  }

  .chore-form__wrap {
    padding: 30px 20px 21px 20px;

    @include m1281 {
      padding: 74px 92px 86px 92px;
    }
  }

  .chore-form__content {
    @include m1281 {
      display: flex;
      gap: 87px;
    }
  }

  .chore-form__h2 {
    font-size: 30px;
    color: $black;
  }

  .chore-form__left,
  .chore-form__right {
    margin-top: 37px;

    @include m1281 {
      width: 546px;
    }
  }

  .chore-form__right {
    margin-top: 0;

    @include m1281 {
      margin-top: 37px;
    }
  }

  label {
    font-size: 18px;
    line-height: 22px;
    display: block;
    margin-bottom: 9px;
  }

  .field-check input[type='radio'] {
    display: none;
  }
  .field-check label {
    display: inline-block;
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: relative;
    margin-right: 0;
    user-select: none;
    padding-left: 20px;
  }
  .field-check label:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 3px;
    border: 1px solid $blue;
    border-radius: 50%;
  }

  .field-check label:has(> input[type='radio']:checked) {
    &:before {
      background: url('/wwwroot/img/form/radio-check.svg') 50% 50% no-repeat;
    }
  }

  .chore-form__radiobuttons {
    @include m1024 {
      display: flex;
      justify-content: space-between;
    }
    @include m1681 {
      display: flex;
      justify-content: unset;
    }
  }

  .field-check {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .radiobuttons {
    .radiobuttons__first {
      @include m1681 {
        margin-right: 241px;
      }
    }
    .radiobuttons__title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 18px;
    }

    label {
      margin-bottom: 0;
    }
    .radiobuttons__second {
      @include m1024 {
        margin-top: 0 !important;
      }
      @include m1681 {
        margin-right: 162px;
      }
    }
    .radiobuttons__second,
    .radiobuttons__third {
      margin-top: 24px;
    }
    .radiobuttons__third {
      margin-bottom: 38px;

      @include m1024 {
        margin-top: 0;
      }
    }
  }

  .chore-form__contacts {
    @include m1281 {
      display: flex;
      gap: 44px;
    }
  }

  .chore-form__contacts-first {
    @include m1281 {
      width: 269px;
    }
  }

  .chore-form__contacts-second {
    @include m1281 {
      width: 763px;
    }
  }

  .chore-form__download {
    margin-top: 45px;

    @include m1281 {
      width: 1076px;
    }
  }
  .chore-form-download {
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
    border-radius: 10px;

    .chore-form-download__wrap {
      padding: 38px 20px 45px 20px;
      display: flex;
      flex-direction: column;

      @include m1281 {
        padding: 38px 158px 45px 20px;
        gap: 60px;
        flex-direction: row;
      }
    }

    .upload-btn {
      position: absolute;
      left: 0;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      z-index: 10;
      max-width: 305px;
    }
    input[type='file']::file-selector-button {
      display: none;
    }
    .upload-wrap {
      position: relative;
      flex-shrink: 0;
    }

    .chore-form-download__button {
      background-color: #fdd9e5;
      border-radius: 56px;
      font-size: 20px;
      line-height: 26px;
      color: #cf656b;
      padding: 13px 75px;
      max-width: 305px;
      transition: 0.3s all;
      cursor: pointer;
      transition: 0.3s all;
      z-index: 10;
      position: relative;
      border: 1px solid #fdd9e5;

      @include m1281 {
        padding: 13px 32px;
      }

      &:hover {
        color: #cf656b;
        background: $white;
        border: 1px dashed #cf656b;
      }
    }

    .chore-form-download__descript {
      font-size: 18px;
      line-height: 22px;
      order: -1;

      @include m1281 {
        order: 1;
      }
    }
  }
  .chore-form-info {
    margin-top: 45px;

    @include m1024 {
      display: flex;
      gap: 43px;
      align-items: center;
      margin-top: 65px;
    }
    .chore-form-info__left {
      background: #ffffff;
      box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
      border-radius: 10px;

      @include m1024 {
        width: 368px;
      }
    }
    .chore-form-info__wrap {
      padding: 20px 22px;
      display: flex;
      align-items: center;
      display: block;
    }
  }
  .form-check {
    .form-check-label {
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .form-check-label {
    p {
      font-size: 16px;
      line-height: 22px;
      width: 200px;

      @include m361 {
        width: 290px;
      }

      @include m1281 {
        width: 800px;
      }

      a {
        text-decoration: underline;
        @include r-semy;
      }
    }
  }

  .chore-form-info input[type='checkbox'] {
    display: none;
  }
  .chore-form-info label {
    display: inline-block;
    cursor: pointer;
    width: 12px;
    height: 12px;
    position: relative;
    margin-right: 0;
    user-select: none;
    padding-left: 20px;
  }
  .chore-form-info label:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 3px;
    border: 1px solid $blue;
  }

  .chore-form-info input[type='checkbox']:checked + label:before {
    background: url('/wwwroot/img/form/check.svg') 50% 50% no-repeat;
  }

  .chore-form-info__right {
    margin: 37px 0 0 22px;

    @include m1024 {
      width: 650px;
      margin: 0;
    }
  }

  .chore-form-info__right-first {
    p {
      br {
        display: block;
        @include m1281 {
          display: none;
        }
      }
    }
  }

  .chore-form-info__right-second {
    margin-top: 24px;

    @include m1281 {
      margin-top: 12px;
    }
  }

  .send-button {
    margin-top: 40px;
    background-color: $blue;
    border-radius: 56px;
    color: $white;
    font-size: 20px;
    line-height: 26px;
    padding: 13px 67px;
    width: fit-content;
    transition: 0.3s all;

    &:hover {
      color: $white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }
  }
  .select2-selection {
    margin-bottom: 24px;
    border: 1.5px solid #fdd9e5;
  }
}

.mask {
  display: none;
  .mask__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 39, 141, 0.85);
    z-index: 30;
  }
  .mask-caption {
    position: absolute;
    overflow: hidden;
    border-radius: 20px;
    padding: 2.5rem;
    background-color: #fff;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(19, 29, 49, 0.16);
    box-shadow: 0px 1px 2px 0px rgba(19, 29, 49, 0.16);
    z-index: 40;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 343px;

    @include m1024 {
      width: 606px;
    }
  }
  .mask__h4 {
    font-size: 20px;
    @include r-semy;
  }
  .mask__p {
    font-size: 16px;
    margin-top: 16px;
  }
  .close-mask {
    width: 17px;
    height: 17px;
    background: url('/wwwroot/img/form/close.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.on {
  display: block;
}

.error-input {
  border: 1px solid red !important;
  border-radius: 10px;
}
.error-input-note {
  font-size: 15px !important;
  color: #ff0000 !important;
  margin-top: 9px !important;
  margin-bottom: 0 !important;
}

/*Стили select2*/
.select2-selection {
  height: 50px !important;
  border-radius: 10px;
  border: none !important;
  outline: none;
}

.select2-selection:focus {
  outline: none;
  background-color: none;
}

.select2-selection__placeholder,
.select2-selection__rendered,
.select2-selection__placeholder {
  color: $black !important;
  line-height: 1.6;
  @include r-regular;
  padding: 0 0 0 3px;
  display: block;
  font-size: 16px;

  @include m768 {
    font-size: 18px;
  }
}
.select2-selection__rendered {
  padding: 12px 0 0 10px;
}

.select2-selection__arrow {
  width: 16px;
  height: 10px;
  background: url('/wwwroot/img/header/check.svg') no-repeat;
  background-size: contain;
  margin: 20px 10px 0 0;
  b {
    display: none;
  }
}

.select2-container {
  width: 100% !important;
}

.select2-dropdown {
  background-color: $white;
  border: none;
}
.select2-results__option {
  padding: 6px;
  max-height: 200px;
  overflow-x: hidden;
  font-size: 16px;
  display: block;

  @include m768 {
    font-size: 18px;
  }
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-results__option[aria-selected='true'] {
  background: none;
  color: #ff908b;
}

.select2-selection--single {
  border-radius: 10px !important;
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.login {
  display: none;

  @include media.m768 {
    display: block;
  }
  background: url('/wwwroot/img/login/login.webp') no-repeat;
  background-size: cover;

  .login-form {
    padding: 24px 0;
    max-width: 791px;
  }
  .login-form__wrap {
    padding: 70px 116px;
    background-color: #fff3f7;
    border-radius: 100px;
  }
  .login-form__h2 {
    font-size: 66px;
    @include mixins.t-regular;
    text-align: center;
  }

  .login-form__content {
    margin-top: 68px;
  }
  .field {
    display: flex;
    flex-direction: column;

    label {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
    }
  }

  input {
    height: 75px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    line-height: 24px;
    padding-left: 20px;
  }

  input :focus {
    outline: none;
    border: none;
  }

  .error-text,
  .phone-error-text {
    display: none;
  }
  .field__top {
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
  }

  .login-form__submit-block {
    margin-top: 77px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login-form__button {
    font-size: 20px;
    line-height: 26px;
    border-radius: 56px;
    background-color: variables.$blue;
    color: variables.$white;
    padding: 20px 113px;
    width: fit-content;
    transition: 0.3s all;

    &:hover {
      color: variables.$white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }
  }

  .login-form__submit-p {
    a {
      @include mixins.r-bold;
    }
  }
  .login-form__bottom {
    margin-top: 54px;
  }

  .login-form-bottom__p {
    font-size: 16px;
    line-height: 22px;
    @include mixins.r-semy;
  }

  .login-form-bottom__link {
    width: 220px;
    height: 60px;
    background-color: variables.$black;
    border-radius: 56px;
    display: block;
    box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);

    @include media.m1024 {
      width: 246px;
    }

    &:nth-child(1) {
      background-image: url('/wwwroot/img/login/google.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &:nth-child(2) {
      background-image: url('/wwwroot/img/login/apple.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
  .login-form-bottom__content {
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
  }
}

.password {
  background: url('/wwwroot/img/password/bg.webp') no-repeat;
  background-size: cover;
  padding: 150px 0;

  @include media.m768 {
    display: block;
  }

  .password-form {
    max-width: 886px;
    margin: 0 auto;
  }
  .password-form__wrapper {
    padding: 94px 162px 110px 162px;
    background-color: #fff3f7;
    border-radius: 100px;
  }
  .password-form__h2 {
    font-size: 46px;
    text-align: center;
  }
  .password-form__content {
    margin-top: 60px;
  }
  .password-form__choose {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .password-form-choose__link {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    color: variables.$blue;
    @include mixins.t-regular;

    &:hover {
      &::before {
        content: '';
        width: 100%;
        height: 2px;
        background-color: variables.$blue;
        position: absolute;
        bottom: -13px;
        left: 0;
      }
    }
  }
  .field {
    margin-top: 33px;
    display: flex;
    flex-direction: column;

    label {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
    }
  }

  input {
    height: 75px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    line-height: 24px;
    padding-left: 20px;
  }

  input :focus {
    outline: none;
    border: none;
  }

  .error-text,
  .phone-error-text {
    display: none;
  }

  .password-form__info {
    display: flex;
    margin-top: 27px;
  }

  .form-check-label {
    p {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .password-form__info input[type='checkbox'] {
    display: none;
  }

  .password-form__info label {
    display: inline-block;
    cursor: pointer;

    height: 12px;
    position: relative;
    margin-right: 0;
    user-select: none;
    padding-left: 20px;
  }

  .password-form__info label:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 3px;
    border: 1px solid variables.$blue;
  }

  .password-form__info input[type='checkbox']:checked + label:before {
    background: url('/wwwroot/img/form/check.svg') 50% 50% no-repeat;
  }

  .password-form__button {
    margin-top: 60px;
    font-size: 20px;
    line-height: 26px;
    background-color: variables.$blue;
    @include mixins.r-bold;
    color: variables.$white;
    border-radius: 56px;
    padding: 20px 56px;
    width: fit-content;
    transition: 0.3s all;

    &:hover {
      color: variables.$white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }
  }
}

.registration {
  background: url('/wwwroot/img/password/bg.webp') no-repeat;
  background-size: cover;
  padding: 150px 0;

  @include media.m768 {
    display: block;
  }

  .registration__container {
    max-width: 886px;
    margin: 0 auto;
  }
  .registration__wrap {
    padding: 94px 162px 110px 162px;
    background-color: #fff3f7;
    border-radius: 100px;
  }

  .registration__h2 {
    font-size: 66px;
    @include mixins.t-regular;
  }
  .registration__steps {
    margin-top: 54px;
    display: flex;
    gap: 77px;

    @include media.m1024 {
      gap: 127px;
    }
  }
  .registration__li {
    width: 41px;
    height: 41px;
    border-radius: 50%;
    background-color: variables.$white;
    box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    z-index: 2;

    &:not(:last-child) {
      position: relative;

      &::after {
        content: '';
        width: 105px;
        height: 2px;
        background-color: variables.$white;
        position: absolute;
        top: 50%;
        left: 100%;

        @include media.m1024 {
          width: 140px;
        }
      }
    }
  }
  .registration__content {
    margin-top: 38px;
  }
  .field {
    margin-top: 33px;
    display: flex;
    flex-direction: column;

    label {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 18px;
    }
  }
  input {
    height: 75px;
    border-radius: 10px;
    border: none;
    font-size: 18px;
    line-height: 24px;
    padding-left: 20px;
  }

  input :focus {
    outline: none;
    border: none;
  }

  .error-text,
  .phone-error-text {
    display: none;
  }
  .registration__buttons {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
  }

  .registration-form__map {
    img {
      width: 100%;
    }

    .registration-form-map__p {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 15px;
    }
  }

  .registration-form-options {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;

    .registration-form-options__option {
      width: 228px;
      height: 53px;
      border: none;
      background-color: variables.$white;
      outline: none;
      border-radius: 10px;
      font-size: 18px;
      line-height: 26px;
    }

    .select2-container {
      width: 180px !important;
      border-radius: 10px;

      @include media.m1024 {
        width: 228px !important;
      }
    }

    .select2-selection__placeholder {
      color: #999;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  .registration-buttons__button {
    font-size: 20px;
    line-height: 26px;
    @include mixins.r-medium;
    border-radius: 56px;
    background-color: variables.$blue;
    padding: 13px 60px;
    width: fit-content;
    color: variables.$white;
    transition: 0.3s all;

    &:hover {
      color: variables.$white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }

    @include media.m1024 {
      padding: 13px 87px;
    }

    &:first-child {
      background-color: #fdd9e5;
      color: #cf656b;
    }
  }

  &--one {
    .registration__li {
      &:nth-child(1) {
        background-color: variables.$blue;

        &::after {
          background-color: variables.$blue;
        }
      }
    }
    .registration-buttons__button {
      &:first-child {
        display: none;
      }
    }
  }

  &--two {
    .registration__li {
      &:nth-child(1) {
        background-color: variables.$blue;

        &::after {
          background-color: variables.$blue;
        }
      }
      &:nth-child(2) {
        background-color: variables.$blue;
      }
    }
  }

  &--third {
    .registration__li {
      &:nth-child(1) {
        background-color: variables.$blue;

        &::after {
          background-color: variables.$blue;
        }
      }
      &:nth-child(2) {
        background-color: variables.$blue;

        &::after {
          background-color: variables.$blue;
        }
      }

      &:nth-child(3) {
        background-color: variables.$blue;
      }
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.join {
  .join__h2 {
    text-align: center;
    @include media.m1681 {
      max-width: 1491px;
    }
  }
  .join__blocks {
    margin-top: 39px;

    @include media.m1681 {
      margin-top: 80px;
    }
  }

  .join-block {
    height: 212px;
    border-radius: 40px;
    overflow: hidden;
    position: relative;

    @include media.m1281 {
      background: #ffffff;
      filter: drop-shadow(0px 4px 25px rgba(18, 18, 18, 0.05));
      height: 308px;
      position: relative;
    }

    .join-block__player-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .join-block__player-overlay {
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.15;
      position: absolute;
    }

    .join-block__player-play {
      width: 108px;
      height: 108px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      background-image: url('/wwwroot/img/video/play-rose.svg');
      background-size: 63px 63px;
      background-position: 65% 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 0.3s all;
      cursor: pointer;

      &:hover {
        background-image: url('/wwwroot/img/video/play.svg');
      }
    }
  }

  .join-block__span {
    color: variables.$blue;
    font-size: 14px;
    line-height: 24px;
    @include mixins.r-bold;
    padding: 5px 15px;
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    display: block;
    position: absolute;
    bottom: 20px;
    left: 30px;

    @include media.m768 {
      font-size: 16px;
      padding: 10px 15px;
      border-radius: 100px;
    }
  }

  .join__bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 40px;

    @include media.m1281 {
      margin-top: 96px;
    }
  }

  .join__navigation {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    width: 100%;

    @include media.m1281 {
      margin-left: 30%;
      width: fit-content;
    }

    .join__prev,
    .join__next {
      display: block;
      cursor: pointer;
      width: 48px;
      height: 48px;
      transition: 0.3s;
    }
    .join__prev {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }
    .join__next {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;
      rotate: 180deg;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }

    .last {
      background: url('/wwwroot/img/join/next-last.png') no-repeat;
      background-size: contain;
      cursor: initial;

      &:hover {
        background: url('/wwwroot/img/join/next-last.png') no-repeat;
        background-size: contain;
      }
    }
  }

  .join__pagination {
    position: relative;
    background: #fdd9e5;
    display: none;

    @include media.m1281 {
      display: block;
      width: 505px;
    }

    span {
      background: #cf656b;
    }
  }
}

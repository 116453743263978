@use "../settings/media";
@use "../settings/mixins";

.method {
  background: url('/wwwroot/img/method/bg-mob.png') no-repeat;
  background-size: 100%;
  margin-top: 230px;
  background-position-y: 125%;

  @include media.m361 {
    background-position-y: 300%;
  }

  @include media.m401 {
    background: none;
  }

  @include media.m768 {
    margin-top: 200px;
  }

  @include media.m1281 {
    background: url('/wwwroot/img/method/bg.png') no-repeat;
    background-size: contain;
    background-size: 100% 82%;
    background-position: 132% 144px;
    margin-top: -99px;
    position: relative;
  }

  @include media.m1681 {
    background-position: 132% 105px;
  }

  .method__content {
    @include media.m1281 {
      margin-top: 0;
      padding-top: 265px;
      display: flex;
      flex-direction: row;
    }
  }
  .method__left {
    @include media.m1281 {
      width: 858px;
    }

    .btn {
      margin-top: 38px;

      @include media.m768 {
        margin-top: 58px;
        padding: 20px 114px;
      }
    }
  }
  .method__h2 {
    font-size: 35px;

    @include media.m768 {
      font-size: 50px;
      text-align: center;
    }

    @include media.m1281 {
      text-align: left;
      margin-top: 60px;
    }

    @include media.m1681 {
      font-size: 70px;
      margin-top: 90px;
    }
  }
  .method__p {
    font-size: 16px;

    @include media.m1281 {
      width: 670px;
    }

    span {
      @include mixins.r-extra;
    }

    &:nth-child(2) {
      margin-top: 32px;

      @include media.m768 {
        margin-top: 48px;
      }
    }

    &:nth-child(3) {
      @include media.m768 {
        margin-top: 20px;
      }
    }
  }

  .method__right {
    height: auto;
    position: relative;
    flex-shrink: 0;
    margin-top: 29px;
    display: flex;
    justify-content: center;

    @include media.m1281 {
      width: 829px;
      margin-top: 0;
    }

    img {
      width: 100%;
      height: auto;
      @include media.m1281 {
        transform: translate(-17%, -39px);
        width: 90%;
      }

      @include media.m1681 {
        transform: translate(3%, -39px);
        width: inherit;
      }
    }
  }
}

@use "../settings/media" as *;
@use "../settings/mixins" as *;
@use "../settings/variables" as *;

.home {
  margin-top: 0;
  color: $blue;
  text-align: center;
  @include t-regular;
  line-height: 1.2;
  background: url('/wwwroot/img/home/bg-mob.webp');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include m768 {
    text-align: inherit;

    background: url('/wwwroot/img/home/bg.webp');
    background-repeat: no-repeat;
    background-size: /*100%,*/ cover;
  }

  .home__container {
    @include m768 {
      position: relative;
      min-height: 800px;
    }

    &::before {
      content: '';
      height: 100%;
      width: 100%;
      background: url('/wwwroot/img/home/alina-mob.webp') no-repeat;
      background-size: contain;
      background-position-y: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      @include m768 {
        content: '';
        height: 69%;
        width: 116%;
        background: url('/wwwroot/img/home/alina.png') no-repeat;
        background-size: cover;
        background-position-x: -62px;
      }
      @include m1024 {
        background-position-x: 6px;
      }

      @include m1281 {
        height: 95%;
        width: 100%;
        background: url('/wwwroot/img/home/alina.png') no-repeat;
        background-size: cover;
        background-position-y: 100%;
        background-position-x: -4px;
      }

      @include m1440 {
        width: 100%;
        height: 100%;
        background: url('/wwwroot/img/home/alina.png') no-repeat;
        background-size: cover;
        background-position-y: 100%;
        background-position-x: -100px;
      }
    }
  }
  .home__h1 {
    padding-top: 20px;
    font-size: 50px;
    @include t-regular;

    @include m768 {
      font-size: 80px;
    }

    @include m1281 {
      font-size: 100px;
    }

    @include m1681 {
      padding-top: 69px;
      font-size: 150px;
      position: relative;
    }

    span {
      @include m1440 {
        position: absolute;
        top: 169px;
        left: 874px;
        display: block;
      }
    }
  }
  .home__desc {
    margin-top: 12px;
    font-size: 25px;

    @include m768 {
      margin-top: 40px;
      font-size: 30px;
      max-width: 420px;
      @include r-regular;
    }

    span {
      display: none;

      @include m768 {
        display: block;
      }

      @include m1440 {
        position: absolute;
        top: 280px;
        left: 77%;
        font-size: 30px;
      }

      @include m1681 {
        position: absolute;
        top: 391px;
        left: 61%;
        font-size: 35px;
      }
    }
  }
  .home__link {
    @include center;
    background: $blue;
    color: $white;
    @include r-medium;
    padding: 21px 25px;
    border-radius: 56px;
    z-index: 3;
    position: relative;
    margin: 78% auto 76px auto;
    width: fit-content;
    font-size: 15px;
    transition: 0.3s all;

    &:hover {
      color: $white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }

    @include m361 {
      font-size: 19px;
    }

    @include m768 {
      width: fit-content;
      margin: 48px 0 0 0;
      font-size: 20px;
      padding: 20px 50px;
    }
  }
  .home__list {
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin-top: 20px;
    z-index: 3;
    position: absolute;
    bottom: -167px;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;

    @include m768 {
      bottom: -144px;
      flex-direction: row;
      gap: 0;
      justify-content: center;
      box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
      border-radius: 10px;
      background-color: $white;
      padding: 21px 108px 24px 108px;
      margin: 0 auto;
      width: 80%;
    }

    @include m1281 {
      max-width: 1231px;
    }
  }
  .home__li {
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 60px 16px 60px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 4px 50px rgba(18, 18, 18, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @include m768 {
      box-shadow: none;
      flex-direction: column;
      border-radius: 0;
      padding: 0;
      font-size: 30px;
    }

    &:not(:last-child) {
      @include m768 {
        margin-right: 100px;
      }

      @include m1281 {
        margin-right: 200px;
      }
    }

    span {
      font-size: 35px;
      line-height: 1.2;
      color: $blue;
      @include t-regular;

      @include m768 {
        font-size: 40px;
      }

      @include m1024 {
        font-size: 50px;
      }

      @include m1281 {
        font-size: 60px;
      }
    }
  }

  &--requisites {
    background: url('/wwwroot/img/home/requisites/bg-mob.webp'),
      url('/wwwroot/img/home/requisites/bg-mob.webp');
    background-repeat: no-repeat;
    background-position-y: 1%, 64%;
    background-size: 100%;

    @include m768 {
      background: url('/wwwroot/img/home/requisites/bg-1.webp'),
        url('/wwwroot/img/home/requisites/bg-2.webp'),
        url('/wwwroot/img/home/requisites/bg-3.webp'),
        url('/wwwroot/img/home/requisites/bg-4.webp');
      background-repeat: no-repeat;
      background-position-y: 0%, 27%, 75%, 100%;
      background-position-x: 0%, 100%, 0%, 100%;
    }

    @include m768 {
      margin-bottom: -77px;
    }

    .home__wrap {
      display: flex;
      flex-direction: column;

      @include m1440 {
        flex-direction: row;
      }
    }
    .home__h1 {
      @include m768 {
        font-size: 45px;
      }

      @include m1440 {
        font-size: 70px;
        padding-top: 120px;
      }
    }
    .home__container {
      &::before {
        display: none;
      }
    }

    .home__left {
      @include m1440 {
        width: 919px;
        flex-shrink: 0;
      }
    }

    .home__right {
      order: -1;
      overflow: hidden;

      @include m1440 {
        order: 1;
        position: relative;
        width: 100%;
        height: auto;
        overflow: visible;
      }

      picture {
        display: flex;
        justify-content: center;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;

        @include m768 {
          width: 55%;
        }

        @include m1440 {
          position: absolute;
          top: -84px;
          right: -15%;
          width: 430px;
        }

        @include m1681{
          width: 780px;
        }
      }
    }

    .home__subtitle {
      font-size: 25px;
      text-align: left;
      @include t-regular;

      @include m1440 {
        font-size: 30px;
        max-width: 640px;
        margin-top: 42px;
      }
    }

    .home__desc {
      margin-top: 30px;
      font-size: 16px;
      text-align: left;
      @include r-regular;

      @include m768 {
        max-width: 100%;
      }

      @include m1440 {
        max-width: 850px;
        margin-top: 0;
      }

      &:nth-child(2) {
        @include m1440 {
          margin-top: 28px;
        }
      }

      &:not(:last-child) {
        @include m1440 {
          margin-bottom: 17px;
        }
      }
    }

    .home__list {
      position: initial;
      width: 100%;
      transform: initial;
      margin-top: 40px;
      gap: 10px;
      box-shadow: none;
      background-color: initial;
      border: none;
      padding: 0;

      @include m768 {
        gap: 20px;
      }

      @include m1281 {
        margin-top: 60px;
      }
    }

    .home__li {
      border-radius: 40px;
      background-color: #fef1f5;
      padding: 20px 11px 20px 6px;
      gap: 15px;
      position: relative;

      @include m768 {
        padding: 120px 11px 33px 11px;
        width: 293px;
        display: block;
      }

      @include m1440 {
        padding: 100px 11px 33px 11px;
        width: 293px;
        display: block;
      }

      &:not(:last-child) {
        @include m768 {
          margin-right: 0;
        }
      }

      img {
        width: 68px;
        height: 68px;

        @include m768 {
          width: 80px;
          height: 80px;
          position: absolute;
          top: 18px;
          left: 50%;
          transform: translateX(-50%);
        }

        @include m1440 {
          width: 116px;
          height: 116px;
          position: absolute;
          top: -33px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    .home-list__p {
      text-align: left;
      @include r-regular;
      line-height: 1.2;
      font-size: 16px;

      @include m1440 {
        text-align: center;
      }

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      a {
        display: block;
        @include r-semy;
        text-decoration: underline;
      }
    }

    .home__content {
      margin-top: 73px;

      @include m768 {
        margin: 40px auto 150px auto;
      }

      @include m1024 {
        margin: 40px auto 300px auto;
      }

      @include m1440 {
        margin: 140px auto 644px auto;
      }
    }
    .home-content {
      .home-content__h3 {
        font-size: 25px;
        @include t-regular;
        text-align: left;

        @include m1440 {
          font-size: 30px;
        }
      }
      .home-content__wrap {
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        @include m768 {
          flex-direction: row;
          justify-content: space-between;
          gap: 175px;
        }
      }
      .home-content__p {
        font-size: 16px;
        text-align: left;

        &:not(:last-child) {
          margin-bottom: 21px;

          @include m1440 {
            margin-bottom: 48px;
          }
        }
      }
    }
  }

  &--choreograph {
    background: url('/wwwroot/img/home/choreograph/bg-mob.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 1px 212px;

    @include m401 {
      background: none;
    }

    @include m1281 {
      background: url('/wwwroot/img/home/choreograph/bg.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .home__h1 {
      font-size: 40px;

      @include m1281 {
        padding-top: 70px;
        font-size: 70px;
        max-width: 640px;
      }

      @include m1440 {
        padding-top: 121px;
      }
    }

    .home__container {
      position: relative;
      &::before {
        // background: url('/wwwroot/img/home/choreograph/alina-mob.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: 42%;
        background: none;
      }

      img {
        width: 100%;
        height: auto;
        position: absolute;
        top: 19%;
        left: 0;

        @include m401 {
          top: 19%;
        }

        @include m768 {
          top: 8%;
        }

        @include m1281 {
          width: initial;
          left: initial;
          top: 11px;
          right: -12%;
          height: 688px;
        }

        @include m1440 {
          top: -10px;
          right: -12%;
          height: 640px;
        }

        @include m1681 {
          top: -10px;
          right: -12%;
          height: 883px;
        }
      }
    }

    .home__subtitle {
      font-size: 25px;
      margin-top: 113%;

      @include m401 {
        margin-top: 83%;
      }

      @include m1281 {
        margin-top: 40px;
        max-width: 700px;
      }
    }

    .home__desc {
      margin-top: 30px;
      font-size: 16px;
      text-align: left;
      max-width: 100%;

      @include m1281 {
        max-width: 604px;
      }
    }
  }
}

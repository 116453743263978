@use "../settings/media";
@use "../settings/mixins";

.news {
  position: relative;

  .news__h2 {
    text-align: center;
  }
  .news__top {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 40px auto 0 auto;
    width: fit-content;

    @include media.m1281 {
      margin: 95px auto 0 auto;
    }
  }
  .news__navigation {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .news__prev,
  .news__prev-2,
  .news__next,
  .news__next-2 {
    display: block;
    cursor: pointer;
    width: 48px;
    height: 48px;
    transition: 0.3s;
  }
  .news__prev,
  .news__prev-2 {
    background: url('/wwwroot/img/join/prev.png') no-repeat;
    background-size: contain;

    &:hover {
      background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
      background-size: contain;
    }
  }
  .news__next,
  .news__next-2 {
    background: url('/wwwroot/img/join/prev.png') no-repeat;
    background-size: contain;
    rotate: 180deg;

    &:hover {
      background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
      background-size: contain;
    }
  }

  .last {
    background: url('/wwwroot/img/join/next-last.png') no-repeat;
    background-size: contain;
    cursor: initial;

    &:hover {
      background: url('/wwwroot/img/join/next-last.png') no-repeat;
      background-size: contain;
    }
  }

  .news__pagination {
    position: relative;
    background: #fdd9e5;
    display: none;

    @include media.m1281 {
      display: block;
      width: 505px;
    }

    span {
      background: #cf656b;
    }
  }

  .disabled_swiper {
    display: none;
  }

  .news__slider {
    margin-top: 40px;

    @include media.m768 {
      padding: 0;
      width: 720px;
      margin: 0 auto;
      width: 100%;
      max-width: 100%;
      padding: 0 16px;
    }

    @include media.m1024 {
      padding: 0;
      width: 928px;
    }

    @include media.m1281 {
      width: 1170px;
    }

    @include media.m1681 {
      width: 1546px;
    }
  }

  .news__wrapper,
  .news__wrapper-2 {
    @include media.m768 {
      margin-top: 80px;
    }
  }

  .news__img {
    overflow: hidden;
    border-radius: 30px;
    height: 229px;

    @include media.m1281 {
      height: 500px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .date {
    font-size: 18px;
    line-height: 1.2;
    @include mixins.r-medium;
    display: block;

    @include media.m1281 {
      margin-bottom: 0;
    }
  }

  .news__description {
    margin-top: 34px;

    @include media.m1281 {
      margin-top: 24px;
    }
  }
  .news__h3 {
    font-size: 16px;
    line-height: 1.2;
    @include mixins.r-regular;

    @include media.m1281 {
      font-size: 16px;
      line-height: 1.2;
    }
  }
}
.news-2 {
  padding-bottom: 54px;
  @include media.m1281 {
    padding-bottom: 85px;
  }
}

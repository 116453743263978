@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.review {
  iframe {
    display: none;
  }

  .review__h2 {
    text-align: center;

    @include media.m1681 {
      max-width: 1491px;
      padding-top: 73px;
    }
  }
  .review__blocks {
    margin-top: 47px;
    padding: 0 20px;

    @include media.m1681 {
      margin-top: 80px;
      padding: 0 30px;
    }
  }

  .review-block {
    height: 212px;
    overflow: hidden;
    border-radius: 30px;
    cursor: pointer;
    transition: 0.3s;

    @include media.m768 {
      height: 250px;
    }

    @include media.m1281 {
      background: #ffffff;
      height: 400px;
      position: relative;
    }

    @include media.m1681 {
      height: 485px;
    }

    &:hover {
      height: 250px;
      transition: 0.3s;

      @include media.m1281 {
        height: 430px;
      }

      @include media.m1681 {
        height: 548px;
      }

      .review-block__play-overlay,
      .review-block__descript {
        opacity: 1;
        transition: 0.3s;
      }
    }

    .review-block__player-overlay {
      height: 100%;
      width: 100%;
      height: 100%;
      background: black;
      opacity: 0.8;
      position: absolute;
    }

    .review-block__player-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .review-block__play-overlay {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.3);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      display: none;

      @include media.m768 {
        display: block;
      }

      @include media.m1281 {
        width: 108px;
        height: 108px;
      }
    }

    .review-block__player-play {
      img {
        width: 65px;
        height: 65px;
        position: absolute;
        top: 50%;
        left: 54.5%;
        transform: translate(-50%, -50%);

        @include media.m768 {
          width: 30px;
          height: 30px;
        }

        @include media.m1281 {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .review-block__descript {
    display: block;
    border-radius: 50px;
    padding: 7px 10px;
    background-color: variables.$white;
    font-size: 14px;
    @include mixins.t-regular;
    color: variables.$blue;
    position: absolute;
    bottom: 32px;
    left: 32px;
    opacity: 0;

    @include media.m1281 {
      padding: 18px 42px;
      font-size: 25px;
      background-color: rgba(255, 255, 255, 0.3);
      color: variables.$white;
      left: 30px;
    }

    @include media.m1681 {
      padding: 18px 28px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .review__swiper-wrapper {
    align-items: center;
    height: 250px;

    @include media.m1281 {
      height: 548px;
    }
  }

  .review__bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 15px;

    @include media.m1681 {
      margin-top: 58px;
      padding-bottom: 95px;
    }
  }

  .review__navigation {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;

    @include media.m1281 {
      width: fit-content;
      margin: 0 0 0 32%;
    }

    .review__prev,
    .review__next {
      display: block;
      cursor: pointer;
      width: 48px;
      height: 48px;
      transition: 0.3s;
    }
    .review__prev {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }
    .review__next {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;
      rotate: 180deg;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }

    .last {
      background: url('/wwwroot/img/join/next-last.png') no-repeat;
      background-size: contain;
      cursor: initial;

      &:hover {
        background: url('/wwwroot/img/join/next-last.png') no-repeat;
        background-size: contain;
      }
    }
  }

  .review__pagination {
    background: #fdd9e5;
    position: absolute;
    top: 50%;
    width: 68px;
    height: 2px;
    left: 43%;
    transform: translateX(-50%);
    display: none;

    @include media.m1281 {
      display: block;
      width: 505px;
      position: relative;
    }

    span {
      background: #cf656b;
    }
  }
}

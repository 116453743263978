@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.mission {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: url('/wwwroot/img/mission/bg-mob.png') no-repeat;
    position: absolute;
    top: 62%;
    left: 0;

    @include media.m401 {
      display: none;
    }

    @include media.m1281 {
      display: block;
      background: url('/wwwroot/img/mission/bg.png') no-repeat;
      top: inherit;
      bottom: -53px;
    }

    @include media.m1921 {
      background-size: 100% 100%;
      bottom: unset;
    }
  }

  .mission__content {
    display: flex;
    flex-direction: column;

    @include media.m1281 {
      flex-direction: row;
    }
  }
  .mission__left {
    display: flex;
    justify-content: center;

    @include media.m768 {
      justify-content: flex-end;
    }

    @include media.m1281 {
      display: block;
      width: 54%;
      flex-shrink: 0;
    }

    img {
      position: relative;
      z-index: 2;
      width: 100%;
      height: auto;

      @include media.m361 {
        width: initial;
      }
    }
  }
  .mission__right {
    order: -1;

    @include media.m1281 {
      order: 1;
    }

    .btn {
      margin-top: 38px;

      @include media.m768 {
        margin-top: 58px;
        padding: 20px 93px;
      }
    }
  }
  .mission__h2 {
    @include media.m1281 {
      margin-top: 67px;
    }
  }
  .mission__desc {
    margin-top: 30px;
    font-size: 18px;
    @include mixins.t-regular;
    color: variables.$black;

    @include media.m1281 {
      margin-top: 37px;
      font-size: 30px;
    }
  }
  .mission__p {
    margin-top: 20px;
    font-size: 16px;

    @include media.m1681 {
      width: 714px;
    }
  }
}

@use "../settings/media";
@use "../settings/mixins";
@use "../settings/variables";

.teachers-main {
  .teachers-main__h2 {
    text-align: center;
  }

  .teachers-main__button {
    font-size: 20px;
    line-height: 26px;
    padding: 20px 31px;
    margin-top: 40px;
    background-color: variables.$blue;
    border-radius: 56px;
    color: variables.$white;
    width: fit-content;
    margin: 40px auto 0 auto;
    transition: 0.3s all;
    flex-direction: column;

    @include media.m1024 {
      flex-direction: row;
    }

    &:hover {
      color: variables.$white;
      background: #f0a5a9;
      box-shadow: 0px 4px 50px rgba(220, 85, 130, 0.5);
    }

    @include media.m1281 {
      margin-top: 60px;
      padding: 20px 60px;
      margin: 60px auto 0 auto;
    }
  }
}

.teachers {
  .teachers__content {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 50px;

    @include media.m1024 {
      flex-direction: row;
      gap: 20px;
    }

    @include media.m1281 {
      flex-direction: row;
      gap: 40px;
    }
  }

  .teachers__swiper-wrapper {
    flex-wrap: nowrap;

    @include media.m1024 {
      flex-wrap: wrap;
    }
  }

  .teachers__left {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.1);
    border-radius: 50px;
    flex-shrink: 0;

    @include media.m768 {
      width: 576px;
      margin: 0 auto;
    }

    @include media.m1024 {
      width: 410px;
    }

    @include media.m1281 {
      width: 546px;
    }

    @include media.m1440 {
      width: auto;
      max-width: 50%;
      flex-shrink: unset;
    }
  }

  .teachers-info {
    opacity: 0;
    height: 0;
    display: flex;
  }

  .active {
    opacity: 1;
    height: 100%;
  }

  .teachers-info__wrapper {
    padding: 15px 15px 44px 15px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    width: 100%;

    @include media.m1440 {
      flex-direction: row;
      gap: 30px;
      padding: 67px 39px 66px 42px;
    }
  }
  .teachers-info__left {
    height: 287px;
    border-radius: 40px;
    width: 100%;
    overflow: hidden;

    @include media.m1440 {
      height: auto;
      max-height: 501px;
      width: 371px;
      max-width: 50%;
      flex-shrink: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teachers-info__h3 {
    font-size: 30px;
    @include mixins.t-regular;
  }
  .teachers-info__subtitle {
    margin-top: 33px;
    font-size: 16px;
    line-height: 22px;
    @include mixins.r-semy;

    @include media.m1681 {
      margin-top: 22px;
      font-size: 18px;
    }
  }
  .teachers-info__descript {
    margin-top: 20px;
    font-size: 16px;

    @include media.m1681 {
      font-size: 18px;
    }
  }
  .teachers-info__h4 {
    margin-top: 23px;
    font-size: 16px;
    @include mixins.r-semy;

    @include media.m1281 {
      margin-top: 33px;
      font-size: 18px;
    }
  }
  .teachers-info__list {
    margin-top: 23px;
  }
  .teachers-info__li {
    position: relative;
    padding-left: 24px;
    font-size: 16px;

    @include media.m1921 {
      font-size: 18px;
    }

    &::before {
      content: '';
      width: 113px;
      height: 18px;
      background: url('/wwwroot/img/teachers/geo.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:not(:last-child) {
      margin-bottom: 20px;

      @include media.m1681 {
        margin-bottom: 28px;
      }
    }
  }
  .teachers__right {
    order: -1;

    @include media.m1024 {
      order: 1;
      width: 500px;
    }

    @include media.m1281 {
      width: 584px;
    }

    @include media.m1681 {
      width: 584px;
    }
  }

  .teachers-slide {
    height: 227px;
    border-radius: 30px;
    overflow: hidden;
    cursor: pointer;

    @include media.m1681 {
      height: 261px;
      box-shadow: 0px 4px 20px rgba(18, 18, 18, 0.1);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .teachers-slide__fio {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 32px;
    @include mixins.center;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 100px;
    width: 236px;

    @include media.m1681 {
    }

    span {
      font-size: 13px;
      @include mixins.t-regular;
      padding: 7px 13px;
      color: variables.$blue;
      display: block;

      @include media.m1281 {
        font-size: 14px;
        padding: 7px 28px;
      }
    }
  }
  .teachers__bottom {
    margin-top: 14px;

    @include media.m1024 {
      margin-top: 38px;
      display: flex;
      align-items: center;
    }
  }

  .teachers__navigation {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin: 0 auto;
    position: relative;

    @include media.m1024 {
      width: fit-content;
      margin: 0;
    }

    .teachers__prev,
    .teachers__next {
      display: block;
      cursor: pointer;
      width: 48px;
      height: 48px;
      transition: 0.3s;
    }
    .teachers__prev {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }
    .teachers__next {
      background: url('/wwwroot/img/join/prev.png') no-repeat;
      background-size: contain;
      rotate: 180deg;

      &:hover {
        background: url('/wwwroot/img/join/prev-hover.png') no-repeat;
        background-size: contain;
      }
    }

    .last {
      background: url('/wwwroot/img/join/next-last.png') no-repeat;
      background-size: contain;
      cursor: initial;

      &:hover {
        background: url('/wwwroot/img/join/next-last.png') no-repeat;
        background-size: contain;
      }
    }
  }

  .teachers__pagination {
    background: #fdd9e5;
    position: absolute;
    top: 50%;
    width: 68px;
    height: 2px;
    left: 40px;
    display: none;

    @include media.m1024 {
      display: block;
      width: 346px;
      position: relative;
    }

    @include media.m1281 {
      width: 432px;
    }

    span {
      background: #cf656b;
    }
  }

  .hide {
    display: none !important;
  }
  .swiper-pagination-lock {
    display: none;
  }
}

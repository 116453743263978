@mixin m361 {
  @media screen and (min-width: 361px) {
    @content;
  }
}

@mixin m401 {
  @media screen and (min-width: 401px) {
    @content;
  }
}

@mixin m768 {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin m1024 {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin m1281 {
  @media screen and (min-width: 1281px) {
    @content;
  }
}

@mixin m1440 {
  @media screen and (min-width: 1440px) {
    @content;
  }
}

@mixin m1681 {
  @media screen and (min-width: 1681px) {
    @content;
  }
}

@mixin m1921 {
  @media screen and (min-width: 1921px) {
    @content;
  }
}
